import ICON_BATHROOM from '../images/toilet-hanging-sign.svg';
import ICON_COUCH from '../images/couch.svg';
import ICON_DOOR from '../images/door.svg';
import ICON_DRINKING from '../images/drinking.svg';
import ICON_IRON from '../images/ironing-machine.svg';
import ICON_LAKE from '../images/lake.svg';
import ICON_LOGIN from '../images/log-in.svg';
import ICON_MINIBAR from '../images/minibar.svg';
import ICON_LOUNGERS from '../images/rest-area.svg';
import ICON_SAT_TV from '../images/satellite-tv.svg';
import ICON_SHAMPOO from '../images/shampoo.svg';
import ICON_SLEEP from '../images/sleep.svg';
import ICON_SWIMMING from '../images/swimming.svg';
import ICON_TERRACE from '../images/terrace.svg';
import ICON_TOWEL from '../images/towel.svg';
import ICON_VIP from '../images/vip.svg';
import ICON_POOLTOWELS from '../images/towels.svg';
import ICON_WIFI from '../images/wifi.svg';


import ICON_FACEBOOK from '../images/icon_facebook.webp';
import ICON_INSTAGRAM from '../images/icon_instagram.webp';
import ICON_TRIPADV from '../images/icon_tripadv.webp';
import ICON_TWITTER from '../images/icon_twitter.webp';
export const FACEBOOK = ICON_FACEBOOK;
export const INSTAGRAM = ICON_INSTAGRAM;
export const TRIPADV = ICON_TRIPADV;
export const TWITTER = ICON_TWITTER;

export const BATHROOM = ICON_BATHROOM;
export const COUCH = ICON_COUCH;
export const DOOR = ICON_DOOR;
export const DRINKING = ICON_DRINKING;
export const IRON = ICON_IRON;
export const LAKE = ICON_LAKE;
export const LOGIN = ICON_LOGIN;
export const MINIBAR = ICON_MINIBAR;
export const POOLTOWELS = ICON_POOLTOWELS;
export const LOUNGERS = ICON_LOUNGERS;
export const SAT_TV = ICON_SAT_TV;
export const SHAMPOO = ICON_SHAMPOO;
export const SLEEP = ICON_SLEEP;
export const SWIMMING = ICON_SWIMMING;
export const TERRACE = ICON_TERRACE;
export const TOWEL = ICON_TOWEL;
export const VIP = ICON_VIP;
export const WIFI = ICON_WIFI ;

