import React from 'react';
import './Footer.css';

const Footer = () => (
  <footer className="footer">
    <div className="container text-center">
      &copy;
      {' '}
      Quinta Lili 2019
    </div>
  </footer>
);

export default Footer;
