/*********************/
//      Navigation   //
/*********************/

export const HOME = 'Home';
export const MENU1 = 'About';
export const MENU2 = 'Rooms';
export const MENU3 = 'Book a room';
export const MENU4 = 'Facilities';
export const MENU5 = 'Services';
export const MENU6 = 'Amenities';
export const MENU7 = 'Tequesquitengo';
export const MENU8 = 'Biercito';
export const MENU9 = 'Contact Us';


/*********************/
//      Content      //
/*********************/
export const ABOUT = {
    TITLE1 : 'BEAUTY & SOUL BOUTIQUE HOUSE HOTEL',
    CONTENT_MAIN:  'Quinta Lili Boutique house hotel Tequesquitengo its a concept that was created in year 2006 which keeps improving and having upgrades up until now. The key elements of our concept are:',
    CONTENT_LISTITEM1: 'Architectonical design.',
    CONTENT_LISTITEM2:'Pleasantness and comfort in all our facilities.',
    CONTENT_LISTITEM3:'Personalized service.',
    CONTENT_LISTITEM4:'The warmth of a home embodied in our mini hotel facilities.',
    CONTENT_LISTITEM5:'Total fulfillment of the needs and expectations of our customers.',
    CONTENT_P1: `The Quinta lili Tequesquitengo experience begins from the booking process, and develops in our tranquility oasis by the shore of the Tequesquitengo Lake by being serviced and attended by our kind staff. We must also mention that we'll be alert and attentive to any services needs and request throughout the whole stay of our guests, and we will make sure that they are always enjoying of a relaxing, warmth and peaceful enviroment.`,
    CONTENT_P2: 'Quinta Lili Tequesquitengo is distributed in two villas with direct access to the lake, where the main Villa focuses the most of the services and common areas. The other villa holds most of the available rooms and terraces. Both villas have the pool as a common element.',
    CONTENT_P3: 'Every one of rooms was carefully designed to allow our guests to experience from a romantic moment with their couples to an excellent weekend with friends and family.',
    CONTENT_P4: 'We at Quinta Lili Tequesquitengo want to give you the most warmth welcome and wish to share our beautiful oasis with you.',
    SIGNATURE_TITLE: 'attentively',
    SIGNATURE_TEXT: 'The Quinta Lili Tequesquitengo Staff.',
};


export const ROOMS = {
    TITLE1 : 'ROOMS',
    DESCRIPTION: `The Quinta Lili Boutique house hotel Tequesquitengo's concept lies in continuous upgrades and it's two sister hotels stablished since year 2006 in Oaxaca State's sea shores. With this concepts three rooms and two Master suites were built, carefully designed with priority in hospitality, comfort, practicity and innovation in architectonical shapes and styles. Always having in mind the harmony with the spaces destined to rest the ambience bastly influenced by the great Tequesquitengo Morelos's Lake.`,
    
    ROOM_NAME1: 'Master Suite QLT',
    ROOM_DESCRIPTION1: `This room has the largest space amongst our available rooms. It has a Roof garden that can be rented or not as part of the room. From its private terrace it is possible to watch all the lake's surroundings, the pool and the native birds of the place.`,
    ROOM_NAME2: 'Roof Garden',
    ROOM_DESCRIPTION2: `Quinta Lili Tequesquitengo's Roof garden has everything you could need to live a wonderful experience and enjoy the place, the lake views, sunny days and the open concept lifestyle in a 2nd floor Roof top in front of the lake. It is a multifunctional space that can be used as a social or recreation area or for rest and relaxation.`,
    ROOM_SPECIAL_TERMS2: 'SPECIAL TERMS:',
    ROOM_SPECIAL_NOTE2: 'To be able to rent this space it is necessary to rent, or be a part of the guests group that rents the Master suite room.',
    ROOM_NAME3: 'Family Suite QLT',
    ROOM_DESCRIPTION3: 'This room was designed with couples in mind. Couples who wish to wake up with wonderful lake views or enjoy a relaxing romantic moment in the private Jacuzzi/mini-pool with acces from the room. Since the Family suite has a 2-floor distribution, it is possible to comfortably host up to 5 people sharing a private bathroom.',
    ROOM_NAME4: 'Red Sunset Room',
    ROOM_DESCRIPTION4: 'Romantic and private, with a modern design and reddish decorative themes comes the Red sunset room, for all our guests who wish to expend their weekend watching a 60" TV in a extremely comfortable bed', 
    ROOM_NAME5: 'Blue View Room',
    ROOM_DESCRIPTION5: `The pool as a decorative element, nice lighting, spreading, relaxing and comfort were the main aspects in this Blue View room's conception, which allows it's guests to spend the days in the comfort of easy access between the terrace, pool, pool loungers and the room`,
    ROOM_NAME6: 'Junior Room',
    ROOM_DESCRIPTION6: 'The most sociable room in the place. Thanks to its location in the building, it is close to any area and common service spaces. This room is planned to be the cheapest room in our hotel.',
    
    TITLE_PPROPERTIES: 'PROPERTIES:',
    ROOM1_PROPERTY1: 'On the first floor.',
    ROOM1_PROPERTY2: 'King size Bed, can request an additional single bed.',
    ROOM1_PROPERTY3: 'Hosts up to 2 people.',
    ROOM1_PROPERTY4: '376 square foot.',

    ROOM2_PROPERTY1: 'On the second floor.',
    ROOM2_PROPERTY2: '2 Single beds.',
    ROOM2_PROPERTY3: 'Hosts up to 12 people.',
    ROOM2_PROPERTY4: '538 square foot.',

    ROOM3_PROPERTY1: '2 floor, on first and second.',
    ROOM3_PROPERTY2: '2 Full beds and one sofa bed.',
    ROOM3_PROPERTY3: 'Hosts up to 5 people.',
    ROOM3_PROPERTY4: '484 square foot.',

    ROOM4_PROPERTY1: 'On the first floor.',
    ROOM4_PROPERTY2: 'Queen Size bed.',
    ROOM4_PROPERTY3: 'Hosts up to 2 people.',
    ROOM4_PROPERTY4: '376 square foot.',
    ROOM4_PROPERTY5: '16.4 feet tall roof.',

    ROOM5_PROPERTY1: 'On the ground floor.',
    ROOM5_PROPERTY2: 'Queen Size bed.',
    ROOM5_PROPERTY3: 'Hosts up to 2 people.',
    ROOM5_PROPERTY4: '376 square foot.',

    ROOM6_PROPERTY1: 'Sobre Planta Baja.',
    ROOM6_PROPERTY2: '2 Single beds.',
    ROOM6_PROPERTY3: 'Hosts up to 2 people.',
    ROOM6_PROPERTY4: '322 square foot.',

    TITLE_AMENITIES: 'AMENITIES:',
    ROOM1_AMENITIES1: 'WiFi.',
    ROOM1_AMENITIES2: 'Satellite TV.',
    ROOM1_AMENITIES3: 'Minibar.',
    ROOM1_AMENITIES4: 'Bathroom towels.',
    ROOM1_AMENITIES5: 'Pool towels.',
    ROOM1_AMENITIES6: '100% Cotton sheets.',
    ROOM1_AMENITIES7: 'Iron and Hair dryer (On request).',
    ROOM1_AMENITIES8: 'Lake view.',
    ROOM1_AMENITIES9: '2-seat Sofa .',
    ROOM1_AMENITIES10: 'Pool view.',
    ROOM1_AMENITIES11: 'Shampoo.',

    ROOM2_AMENITIES1: 'WiFi.',
    ROOM2_AMENITIES2: 'Satellite.',
    ROOM2_AMENITIES3: 'Pool view.',
    ROOM2_AMENITIES4: 'Lake view.',
    ROOM2_AMENITIES5: 'Pool Loungers.',
    ROOM2_AMENITIES6: 'Outdoor tables.',

    ROOM3_AMENITIES1: 'WiFi.',
    ROOM3_AMENITIES2: 'Satellite.',
    ROOM3_AMENITIES3: 'Refrigerator.',
    ROOM3_AMENITIES4: 'Bathroom towels.',
    ROOM3_AMENITIES5: 'Pool towels.',
    ROOM3_AMENITIES6: '100% Cotton sheets.',
    ROOM3_AMENITIES7: 'Iron and Hair dryer (On request).',
    ROOM3_AMENITIES8: 'Lake view.',
    ROOM3_AMENITIES9: 'Sofa Bed.',
    ROOM3_AMENITIES10: 'Pool view.',
    ROOM3_AMENITIES11: 'Shampoo.',

    ROOM4_AMENITIES1: 'WiFi.',
    ROOM4_AMENITIES2: 'Satellite.',
    ROOM4_AMENITIES3: 'Refrigerator.',
    ROOM4_AMENITIES4: 'Bathroom towels.',
    ROOM4_AMENITIES5: 'Pool towels.',
    ROOM4_AMENITIES6: '100% Cotton sheets.',
    ROOM4_AMENITIES7: 'Iron and Hair dryer (On request).',
    ROOM4_AMENITIES8: 'Outdoor Chairs.',
    ROOM4_AMENITIES9: 'Pool view.',
    ROOM4_AMENITIES10: 'Shampoo.',

    ROOM5_AMENITIES1: 'WiFi.',
    ROOM5_AMENITIES2: 'Bathroom towels.',
    ROOM5_AMENITIES3: 'Pool towels.',
    ROOM5_AMENITIES4: '100% Cotton sheets.',
    ROOM5_AMENITIES5: 'Iron and Hair dryer (On request).',
    ROOM5_AMENITIES6: 'Outdoor Chairs.',
    ROOM5_AMENITIES7: 'Pool view.',
    ROOM5_AMENITIES8: 'Pool Loungers.',
    ROOM5_AMENITIES9: 'Shampoo.',

    ROOM6_AMENITIES1: 'WiFi.',
    ROOM6_AMENITIES2: 'Bathroom towels.',
    ROOM6_AMENITIES3: 'Pool towels.',
    ROOM6_AMENITIES4: '100% Cotton sheets.',
    ROOM6_AMENITIES5: 'Iron and Hair dryer (On request).',
    ROOM6_AMENITIES6: 'Pool view.',
    ROOM6_AMENITIES7: 'Pool Loungers.',
    ROOM6_AMENITIES8: 'Shampoo.',
    
    TITLE_FACILITIES: 'FACILITIES:',
    ROOM1_FACILITIES1: 'Private terrace.',
    ROOM1_FACILITIES2: 'Private Bathroom.',
    ROOM1_FACILITIES3: 'Independent access.',
    ROOM1_FACILITIES4: 'Roof Garden (On request).',

    ROOM2_FACILITIES1: 'Terraza.',
    ROOM2_FACILITIES2: 'Private Bathroom.',
    ROOM2_FACILITIES3: 'Independent access.',
    ROOM2_FACILITIES4: 'Jacuzzi Privado (8 personas).',
    ROOM2_FACILITIES5: 'Outdoor grill area.',

    ROOM3_FACILITIES1: 'Private terrace.',
    ROOM3_FACILITIES2: 'Terraza Compartida.',
    ROOM3_FACILITIES3: 'Private Bathroom.',
    ROOM3_FACILITIES4: 'Independent access.',
    ROOM3_FACILITIES5: 'Mini-pool Jacuzzi.',
    ROOM3_FACILITIES6: 'Lookout area (Bring a telescope or binoculars)',

    ROOM4_FACILITIES1: 'Private terrace.',
    ROOM4_FACILITIES2: 'Private Bathroom.',
    ROOM4_FACILITIES3: 'Independent access.',

    ROOM5_FACILITIES1: 'Terraza Compartida.',
    ROOM5_FACILITIES2: 'Private Bathroom.',
    ROOM5_FACILITIES3: 'Independent access.',

    ROOM6_FACILITIES1: 'Terraza Compartida.',
    ROOM6_FACILITIES2: 'Private Bathroom.',
    ROOM6_FACILITIES3: 'Independent access.',

    TITLE_CHECKTIMES: 'Check-In and Out Times:',
    ROOM_CHECKIN: 'Check-In: 2:00 P.M.',
    ROOM_CHECKOUT: 'Check-Out: 12:00 P.M.',

};

export const RESERVE = {
    TITLE1 :                    'BOOK A ROOM',
    SUBHEAD1:                   'To make a reservation request, please complete the form below and we will respond very promptly.',
    SUCCESS_TITLE: 'RESERVATION REQUEST SUCCESSFULLY SENT!',
    SUCCESS_MESSAGE: 'You should get a receipt confirmation in your email. we will contact you to confirm your reservation, If you do not receive this confirmation, please call us at +52 1 (55) 5406-4759', 

    FORM_SUB_PERSONAL: 'Personal Details:',

    FORM_LABEL_NAME: 'Full Name:',
    FORM_LABEL_EMAIL : 'Email:',
    FORM_LABEL_PHONE : 'Phone:',
    FORM_LABEL_COUNTRY : 'Country:',
    FORM_LABEL_CITY : 'City:',
    FORM_LABEL_AGE : 'Age:',
    FORM_LABEL_AGE_SELECT : 'Please Select...',

    FORM_SUB_BOOKING : 'Booking Details:',

    FORM_LABEL_CHECKIN_DATE : 'Check-in date:',
    FORM_LABEL_CHECKOUT_DATE : 'Check-out date:',
    FORM_LABEL_ROOMTYPE : 'Type of room:',
    FORM_LABEL_ROOMTYPE_SELECT: 'Please Select...',
    FORM_LABEL_ROOMTYPE_SELECT_OP1: 'Junior (Ground floor)',
    FORM_LABEL_ROOMTYPE_SELECT_OP2: 'BlueView (Ground floor)',
    FORM_LABEL_ROOMTYPE_SELECT_OP3: 'RedSunset (First floor)',
    FORM_LABEL_ROOMTYPE_SELECT_OP4: 'Family Suite (Two floor)',
    FORM_LABEL_ROOMTYPE_SELECT_OP5: 'Master Suite (Second floor)',
    FORM_LABEL_ROOMTYPE_SELECT_OP6: 'Master Suite + Roof Garden (2nd floor and Roof)',
    FORM_LABEL_NUM_ROOMS : 'Number of rooms:',
    FORM_LABEL_NUM_ROOMS_SELECT: 'Please Select...',
    FORM_LABEL_NUM_ROOMS_SELECT_OP6: 'More than 5',
    FORM_LABEL_NUM_ADULTS : 'Number of adults:',
    FORM_LABEL_NUM_ADULTS_SELECT: 'Please Select...',
    FORM_LABEL_NUM_ADULTS_SELECT_OP6: 'More than 5',
    FORM_LABEL_CHILDREN12 : 'Children 6 to 12 years old:',
    FORM_LABEL_CHILDREN12_SELECT: 'Please Select...',
    FORM_LABEL_CHILDREN12_SELECT_OP6: 'More than 5',
    FORM_LABEL_CHILDREN6 : 'Children under 6 years old:',
    FORM_LABEL_CHILDREN6_SELECT : 'Please Select...',
    FORM_LABEL_CHILDREN6_SELECT_OP6: 'More than 5',

    FORM_SUB_MOREINFO : 'Aditional Information:',

    FORM_LABEL_BREAKFAST : 'For breakfast I would like...',
    FORM_LABEL_BREAKFAST_SELECT : 'Please Select...',
    FORM_LABEL_BREAKFAST_SELECT_OP1 : 'Quinta lili Style (Mexican food)',
    FORM_LABEL_BREAKFAST_SELECT_OP2 : 'Fruit, Juice and Eggs',
    FORM_LABEL_BREAKFAST_SELECT_OP3 : 'Special Requirements',
    FORM_LABEL_INTERESTS : 'Interested in:',
    FORM_LABEL_INTERESTS_SELECT : 'Please Select...',
    FORM_LABEL_INTERESTS_SELECT_OP1 : 'Boat ride',
    FORM_LABEL_INTERESTS_SELECT_OP2 : 'Water Ski',
    FORM_LABEL_INTERESTS_SELECT_OP3 : 'Quad ATVs',
    FORM_LABEL_INTERESTS_SELECT_OP4 : 'Cook',
    FORM_LABEL_INTERESTS_SELECT_OP5 : 'Kayaks',
    FORM_LABEL_INTERESTS_SELECT_OP6 : 'Party planning',
    FORM_LABEL_HOW_FINDUS : 'How did you find us?',
    FORM_LABEL_HOW_FINDUS_SELECT : 'Please Select...',
    FORM_LABEL_HOW_FINDUS_SELECT_OP1 : 'Tripadvisor',
    FORM_LABEL_HOW_FINDUS_SELECT_OP2 : 'Google',
    FORM_LABEL_HOW_FINDUS_SELECT_OP3 : 'Friends',
    FORM_LABEL_HOW_FINDUS_SELECT_OP4 : 'Other (Please tell us in message)',
    FORM_LABEL_MESSAGE : 'Message:',

};

export const FACILITIES = {
    TITLE1 : 'FACILITIES',
    CONTENT_MAIN:  `At Quinta Lili Tequesquitengo we have comfortable, functional y practical spaces which will guarantee your rest and pleasurable experience. spaces such as: `, 
    CONTENT_LISTITEM1: 'Pool and Pool Loungers.',
    CONTENT_LISTITEM2:'Roof Garden.',
    CONTENT_LISTITEM3:'Mini Pool.',
    CONTENT_LISTITEM4:'Honor Bar.',
    CONTENT_LISTITEM5:'Parking Lot.',
    CONTENT_LISTITEM6:'Private Access to the Lake.',
    CONTENT_LISTITEM7:'Grill Area, Guests Kitchen, Bar.',
    CONTENT_LISTITEM8:'Shared and Private Terraces with View to the Lake.',
    CONTENT_P1: '',
};

export const SERVICES = {
    TITLE1 : 'SERVICES',
    CONTENT_MAIN:  `Our goal here at Quinta lili Tequesquitengo is to make our guests feel like if they were at home, that is why we have integrated our little oasis with the following services:`, 
    CONTENT_LISTITEM1: 'Gourmet Breakfast.',
    CONTENT_LISTITEM2:'Wifi.',
    CONTENT_LISTITEM3:'Honor Bar.',
    CONTENT_LISTITEM4:'Jacuzzi / Mini-Pool.',
    CONTENT_LISTITEM5:'Cook (Upon request) and grill.',
    CONTENT_LISTITEM6:'Stoned access to the lake.',
    CONTENT_LISTITEM7:'Kayaks.',
    CONTENT_LISTITEM8:'Boat.',
    CONTENT_LISTITEM9:'Party Planner.',
    CONTENT_LISTITEM10:'Quad ATVs.',
    CONTENT_P1: '',
};

export const AMENITIES = {
    TITLE1 : 'AMENITIES',
    CONTENT_MAIN:  `Because little details make great memories, we please to inform you of the following amenities which we know will make your stay unforgettable:`, 
    CONTENT_LISTITEM1: '100% Cotton Sheets, Pillows and Towels.',
    CONTENT_LISTITEM2:'Bathroom Towels, Pool Towels.',
    CONTENT_LISTITEM3:'Shampoo.',
    CONTENT_LISTITEM4:'Satellite TV.',
    CONTENT_LISTITEM5:'Roku (Upon request).',
    CONTENT_LISTITEM6:'Insect Repelling Mat device.',
    CONTENT_LISTITEM7:'Fan.',
    CONTENT_LISTITEM8:'Purified water (Only in our Bar).',
    CONTENT_LISTITEM9:'In-Room Refrigerator.',
    CONTENT_LISTITEM10:'Extra Beds (Upon request).',
    CONTENT_LISTITEM11:'Daily Room cleaning service.',
    CONTENT_LISTITEM12:'Jacuzzi / Mini-Pool.',
    CONTENT_P1: '',
};

export const CONTACT = {
    TITLE1 : 'CONTACT US',
    SUBTITLE1: 'For questions or inquires:',
    EMAIL:  `reservations@quintalili.com`, 
    WHATSAPP_TITLE: 'LIVE CHAT',
    WHATSAPP: '+52-1-(55)5406-4759',
    LINKS_TITLE: 'RELATED WEBSITES:',
    LINK1: 'http://www.villalili.mx',
    LINK2: 'http://www.quintalili.mx',
};

export const BIERCITO = {
    TITLE1 : 'BIERCITO CRAFT BEER',
    CONTENT_MAIN: 'Quinta Lili Tequesquitengo has the pleasure to offer its homebrew homemade beer specially brewed by us in a creative way and with excellent quality products.',
    CONTENT_MAIN2: `Let's shout Salud, Cheers , Prost or Slainte with a Biercito in Teques.`,
}

export const TEQUES = {
    TITLE1: 'TEQUESQUITENGO MORELOS',
    CONTENT_MAIN: 'Tequesquitengo is a small town in Jojutla in Morelos state of Mexico. Since it is located by the shores of the Tequesquitengo lake it has become one of the most importat touristic places around.',
    CONTENT_MAIN2: 'It is located 111 KMS from Mexico city and 39KMS to the south of Cuernavaca city. The lake has the shape of a water drop with the following dimensions: The top mirror of water size is 4.2 km long, 2.5 km wide and has a 16 km perimeter, it is located 887.37 meters over sea level.',
    CONTENT_MAIN3: "Quinta Lili Tequesquitengo has a privileged location in the lake's area, a zone known as 'La bota' or The boot which is just some minutes away from the 'autopista del sol' freeway, El puerto de Tequesquitengo port and some attractions like:",
    CONTENT_LISTITEM1: 'Mexico gardens.',
    CONTENT_LISTITEM2:'Skydiving clubs.',
    CONTENT_LISTITEM3:'Water skiing clubs.',
    CONTENT_LISTITEM4:'Water parks.',
    CONTENT_LISTITEM5:'Rafting and speleology (in Rio Amacuzac).',
    CONTENT_LISTITEM6:'Hot air ballons.',
    CONTENT_LISTITEM7:'Haciendas and Hotels for Social event hosting.',
    CONTENT_LISTITEM8:'Taxco, Guerrero is just 65 KMS away.',
    CONTENT_LISTITEM9:'diving and sports fishing.',
    LINKS_TITLE:'Some links about Tequesquitengo:',
    LINK1: 'https://jardinesdemexico.com/',
    LINK2: 'https://skydivemexico.mx/',
    LINK3: 'https://elrollo.com.mx/',
    LINK4: 'http://www.acuaski.com/',
    LINK5: 'https://www.mexicorafting.com/',
}

/*********************/
//      Buttons      //
/*********************/

export const BUTTON = {
    CTA_RESERVE : 'Book Now!',
    VIEW : 'View',
    CONTACT: 'Contact Us',
    FORM_SEND: 'Send Email',
    BTN_GO: 'See more',
    BTN_BACK_UP: 'Go back up',
    BTN_BACK_ROOMS: 'Back to Rooms',
    BTN_BACK_HOME: 'Back to Home',
};

/*********************/
//      Alerts      //
/*********************/

export const ALERTS = {
    EMAIL_VALID : 'Email looks correct!',
    EMAIL_INVALID: 'It must be a valid email',
}
