import React, { Component } from 'react';
import { Link } from 'gatsby';
import * as LNK from '../../../res/links';
import * as TXT from '../../../res/strings';
import * as TXTEN from '../../../res/strings_en';
import { TRIPADV, FACEBOOK, INSTAGRAM, TWITTER} from '../../../res/icons';
import './autoShowNavBar.css';
import { Container, Collapse, Navbar, NavbarToggler, Nav, NavItem } from 'reactstrap';
import HeaderImg from '../../../images/Logo_Quinta_lili_wide.png';



const isActive = ({ isCurrent }) => {
  return isCurrent ? { className: "active" } : null
};

const ExactNavLink = props => (
  <Link getProps={isActive} {...props} />
);

const isPartiallyActive = ({
  isPartiallyCurrent
}) => {
  return isPartiallyCurrent
    ? { className: "active" }
    : null
};

const PartialNavLink = props => (
  <Link getProps={isPartiallyActive} {...props} />
);

const ListLink = (props) => {
  if (props.isPurelink !== undefined && props.isPurelink === true) {
    return (
      <NavItem style={{marginBottom: '0'}}>
        <div className="navlink">
          {
            props.external === true
            ? <a href={props.route} target="_blank" rel="noopener noreferrer">{props.routeName}</a>
            : <a href={props.route}>{props.routeName}</a>
          }
        </div>
      </NavItem>
    )
  } else {
    return (
      <NavItem style={{marginBottom: '0'}}>
        <div className="navlink">
          {
            props.exact === true
            ? <ExactNavLink to={props.route}>{props.routeName}</ExactNavLink>
            : <PartialNavLink to={props.route}>{props.routeName}</PartialNavLink>
          }
        </div>
      </NavItem>
    )
  }
};




class AutoShowNavBar extends Component {
  constructor (props) {
    super(props);
    this.toggle = this.toggleNavbar.bind(this);

    this.state = {
      isOpen: false,
    }

    // https://www.w3schools.com/howto/howto_js_navbar_hide_scroll.asp
    if (typeof window !== 'undefined') {
      let prevScrollpos = window.pageYOffset;
      window.onscroll = function () {
        const maxScroll = document.body.clientHeight - window.innerHeight;
        let currentScrollPos = window.pageYOffset;
        if (
            (maxScroll > 0 && prevScrollpos > currentScrollPos && prevScrollpos <= maxScroll) 
          || (maxScroll <= 0 && prevScrollpos > currentScrollPos)
          || (prevScrollpos <= 0 && currentScrollPos <= 0)
          ) {
          document.getElementById("navbar").style.top = "0";
        } else {
          document.getElementById("navbar").style.top = "-7.0rem";
        }
        prevScrollpos = currentScrollPos;
      }
    }
    
    
  }
  
  toggleNavbar() {
    this.setState({
      isOpen: !this.state.isOpen
    });
  }

  render () {
    const APP_LANG = this.props.lang;
    return (
      <div style={{fontSize: '1.2rem'}}>
        <Navbar id="navbar" color="light" light expand="md" fixed="top">
            <div className="navbarbrand">
              <Link to={LNK.HOME}>
                <img src={HeaderImg} width="170" height="auto" className="d-inline-block align-top" alt="profile" style={{marginBottom: '0', maxWidth: '200px'}}/> &nbsp;
                {this.props.siteTitle}
              </Link>
            </div>
            <NavbarToggler className="mr-2" onClick={this.toggle} />
            <Collapse isOpen={this.state.isOpen} navbar>
              <Container>
              <Nav className="ml-auto justify-content-center" navbar>
                <ListLink exact={true} route={LNK.HOME} routeName={APP_LANG === 'EN' ? TXTEN.HOME : TXT.HOME}/>
                <ListLink route={LNK.MENU1} routeName={APP_LANG === 'EN' ? TXTEN.MENU1 : TXT.MENU1} onClick={this.toggle}/>
                <ListLink route={LNK.MENU2} routeName={APP_LANG === 'EN' ? TXTEN.MENU2 : TXT.MENU2} onClick={this.toggle}/>
                <ListLink route={LNK.MENU3} routeName={APP_LANG === 'EN' ? TXTEN.MENU3 : TXT.MENU3} onClick={this.toggle}/>
                <ListLink route={LNK.MENU4} routeName={APP_LANG === 'EN' ? TXTEN.MENU4 : TXT.MENU4} onClick={this.toggle}/>
                <div className="d-md-none d-lg-block">
                <ListLink route={LNK.MENU5} routeName={APP_LANG === 'EN' ? TXTEN.MENU5 : TXT.MENU5} onClick={this.toggle}/>
                </div>
                <div className="d-md-none d-lg-block">
                <ListLink route={LNK.MENU6} routeName={APP_LANG === 'EN' ? TXTEN.MENU6 : TXT.MENU6} onClick={this.toggle}/>
                </div>
                <div className="d-md-none d-lg-block">
                <ListLink route={LNK.MENU7} routeName={APP_LANG === 'EN' ? TXTEN.MENU7 : TXT.MENU7} onClick={this.toggle}/>
                </div>
                <div className="d-md-none d-lg-block">
                <ListLink route={LNK.MENU8} routeName={APP_LANG === 'EN' ? TXTEN.MENU8 : TXT.MENU8} onClick={this.toggle}/>
                </div>
                <div className="d-md-none d-lg-block">
                <ListLink route={LNK.MENU9} routeName={APP_LANG === 'EN' ? TXTEN.MENU9 : TXT.MENU9} onClick={this.toggle}/>
                </div>
              </Nav>
              <Nav className="ml-auto justify-content-center">
              <div className="d-none d-md-block d-lg-none">
                <ListLink route={LNK.MENU5} routeName={APP_LANG === 'EN' ? TXTEN.MENU5 : TXT.MENU5} onClick={this.toggle}/>
                </div>
                <div className="d-none d-md-block d-lg-none">
                <ListLink route={LNK.MENU6} routeName={APP_LANG === 'EN' ? TXTEN.MENU6 : TXT.MENU6} onClick={this.toggle}/>
                </div>
                <div className="d-none d-md-block d-lg-none">
                <ListLink route={LNK.MENU7} routeName={APP_LANG === 'EN' ? TXTEN.MENU7 : TXT.MENU7} onClick={this.toggle}/>
                </div>
                <div className="d-none d-md-block d-lg-none">
                <ListLink route={LNK.MENU8} routeName={APP_LANG === 'EN' ? TXTEN.MENU8 : TXT.MENU8} onClick={this.toggle}/>
                </div>
                <div className="d-none d-md-block d-lg-none">
                <ListLink route={LNK.MENU9} routeName={APP_LANG === 'EN' ? TXTEN.MENU9 : TXT.MENU9} onClick={this.toggle}/>
                </div>
              </Nav>
              </Container>
              <div className="language">
              Idioma: / Language:<br />
              <span onClick={() => {this.props.toggleLanguage('ES')}}>
                ES {' '}
              </span>
              <span onClick={() => {this.props.toggleLanguage('EN')}}>
                | EN
              </span>
            </div>
            </Collapse>
            <div className="d-md-none justify-content-center text-center ml-auto mr-auto" >
          <a href="#TRIP" rel="noopener noreferrer"><img src={TRIPADV} /></a>
          <a href="#FACE"  rel="noopener noreferrer"><img src={FACEBOOK} /></a>
          <a href="#TWITT" rel="noopener noreferrer"><img src={TWITTER} /></a>
          <a href="#INSTA" rel="noopener noreferrer"><img src={INSTAGRAM} /></a>
        </div>
        </Navbar>
        <div className="fixed-right d-none d-md-block" id="rightMenu" >
          <a href="#TRIP" rel="noopener noreferrer"><img src={TRIPADV} /></a>
          <a href="#FACE" rel="noopener noreferrer"><img src={FACEBOOK} /></a>
          <a href="#TWITT" rel="noopener noreferrer"><img src={TWITTER} /></a>
          <a href="#INSTA" rel="noopener noreferrer"><img src={INSTAGRAM} /></a>
        </div>
      </div>
    );
  }
}

export default AutoShowNavBar;