

/*********************/
//      Navigation   //
/*********************/
export const HOME = 'Inicio';
export const MENU1 = 'Acerca';
export const MENU2 = 'Habitaciones';
export const MENU3 = 'Reservar';
export const MENU4 = 'Instalaciones';
export const MENU5 = 'Servicios';
export const MENU6 = 'Amenidades';
export const MENU7 = 'Tequesquitengo';
export const MENU8 = 'Biercito';
export const MENU9 = 'Contacto';


/*********************/
//      Content      //
/*********************/
export const ABOUT = {
    TITLE1 : 'BEAUTY & SOUL BOUTIQUE HOUSE HOTEL',
    CONTENT_MAIN:  'Quinta Lili Boutique house hotel Tequesquitengo es un concepto creado en el año 2006 sin dejar de tener mejoras continuas a la fecha. Los elementos claves de nuestro concepto son:', 
    CONTENT_LISTITEM1: 'Diseño arquitectónico.',
    CONTENT_LISTITEM2:'Comodidad y confort de nuestras instalaciones.',
    CONTENT_LISTITEM3:'Servicio Personalizado.',
    CONTENT_LISTITEM4:'Calidez de un hogar plasmada en las instalaciones de un Mini hotel.',
    CONTENT_LISTITEM5:'Receptividad Total de las necesidades y expectativas de Nuestros Clientes.',
    CONTENT_P1: 'La experiencia Quinta lili Tequesquitengo inicia desde el proceso de la reservación, se desarrolla en nuestro oasis de tranquilidad a las orillas del lago todo el tiempo atendido por nuestro amable Staff . Cabe mencionar que durante la estancia de nuestros clientes estaremos al pendiente de cómo ayudarlos,  sus necesidades de servicio y aseguraremos siempre un ambiente relajado, tranquilo y Cálido.',
    CONTENT_P2: 'Quinta Lili Tequesquitengo está distribuida en dos villas con acceso directo al Lago, donde la Villa Principal concentra la mayoría de Servicios y áreas comunes. La villa secundaria concentra la mayoría de las habitaciones y terrazas disponibles. Ambas villas tienen como elemento común la Alberca.',
    CONTENT_P3: 'Cada una de nuestras habitaciones fueron diseñadas cuidadosamente para permitir a nuestros clientes desde planear un momento Romántico hasta pasar un fin de semana entre amigos y familiares.',
    CONTENT_P4: 'Todos en Quinta Lili Tequesquitengo les damos una cálida bienvenida y deseamos compartir nuestro Oasis con Ustedes.',
    SIGNATURE_TITLE: 'Atentamente',
    SIGNATURE_TEXT: 'El equipo de Quinta Lili Tequesquitengo.',
};

export const ROOMS = {
    TITLE1 : 'HABITACIONES',
    DESCRIPTION: 'El Concepto Quinta Lili Boutique house hotel Tequesquitengo consiste de una mejora continua sobre sus dos propiedades hermanas establecidas desde el año 2006 en las costas del Estado de Oaxaca. Con este antecedente se crearon 3 habitaciones y dos máster suites cuidadosamente diseñadas donde priorizamos la habitabilidad, el confort, la practicidad y la innovación en formas arquitectónicas. Teniendo siempre en cuenta la armonía de los espacios destinados al descanso y el medio ambiente influenciado vastamente por el Gran Lago de Tequesquitengo Morelos.',
    
    ROOM_NAME1: 'Master Suite QLT',
    ROOM_DESCRIPTION1: 'Esta habitación es la de mayor espacio dentro del grupo de habitaciones  disponibles, cuenta con un roof garden que puede ser rentado como Parte o no de la habitación , desde su terraza privada es posible  Observar los alrededores del lago, la alberca del conjunto y las aves  Nativas del lugar.',
    ROOM_NAME2: 'Roof Garden',
    ROOM_DESCRIPTION2: 'El roof Garden de Quinta Lili Tequesquitengo cuenta con todo lo necesario para vivir la experiencia de disfrutar el medio ambiente, las vistas hacia el lago, los días soleados y la propuesta open concept en una terraza ubicada sobre el 2ndo frente al Lago. Es un espacio multifuncional susceptible de ser utilizado como área social  y de esparcimiento o quizás para área de descanso y relajación.',
    ROOM_SPECIAL_TERMS2: 'CONSIDERACIÓN ESPECIAL:',
    ROOM_SPECIAL_NOTE2: 'Para poder Reservar este espacio es necesario reservar la habitación Master suite o ser parte del grupo de huéspedes hospedados en dicha habitación.',
    ROOM_NAME3: 'Family Suite QLT',
    ROOM_DESCRIPTION3: 'Esta habitación fue diseñada pensando en parejas que deseen despertar con vistas increíbles al lago y además quieran planear  disfrutar de un momento romántico y de relajación  en el jacuzzi- mini alberca privada con acceso desde la habitación. Puesto que la Family suite está distribuida en dos pisos es posible acomodar cómodamente hasta 5 personas compartiendo de un baño privado.',
    ROOM_NAME4: 'Red Sunset Room',
    ROOM_DESCRIPTION4: 'Romántica, privada, con un diseño moderno con temas rojos de decoración se presenta la oferta de la habitación red sunset para todos aquellos clientes que deseen pasar todo su tiene disfrutando de ver tv (60”) y de una cama extremadamente confortable.',
    ROOM_NAME5: 'Blue View Room',
    ROOM_DESCRIPTION5: 'La piscina como un elemento de decoración, iluminación, esparcimiento, relajación y confort fue el  punto clave en la concepción de la habitación Blue view, la cual permite que permite a sus habitantes  pasar todo el día entre la comodidad del acceso directo entre terraza, camastros, alberca y habitación.',
    ROOM_NAME6: 'Junior Room',
    ROOM_DESCRIPTION6: 'La habitación más social del conjunto, debido a ubicación dentro del conjunto esta habitación esta a un paso de áreas, servicios comunes. Fue pensada para ser ofrecida con la tarifa más baja del conjunto.',
    
    TITLE_PPROPERTIES: 'CARACTERISTICAS:',
    ROOM1_PROPERTY1: 'Sobre Primer piso.',
    ROOM1_PROPERTY2: 'Cama King size ,susceptible a una cama individual extra.',
    ROOM1_PROPERTY3: 'Duermen 2 personas.',
    ROOM1_PROPERTY4: '35 Metros cuadrados.',

    ROOM2_PROPERTY1: 'Sobre segundo piso.',
    ROOM2_PROPERTY2: '2 Camas individuales.',
    ROOM2_PROPERTY3: 'Aloja hasta 12 personas.',
    ROOM2_PROPERTY4: '50 metros cuadrados.',

    ROOM3_PROPERTY1: 'Sobre primer y segundo piso.',
    ROOM3_PROPERTY2: '2 Camas matrimoniales y sofá cama individual.',
    ROOM3_PROPERTY3: 'Aloja hasta 5 personas.',
    ROOM3_PROPERTY4: '45 metros cuadrados.',

    ROOM4_PROPERTY1: 'Sobre primer piso.',
    ROOM4_PROPERTY2: 'Cama Queen Size.',
    ROOM4_PROPERTY3: 'Duermen 2 personas.',
    ROOM4_PROPERTY4: '35 metros cuadrados.',
    ROOM4_PROPERTY5: 'Altura de techos 5 metros.',

    ROOM5_PROPERTY1: 'Sobre Planta Baja.',
    ROOM5_PROPERTY2: 'Cama Queen Size.',
    ROOM5_PROPERTY3: 'Duermen 2 personas.',
    ROOM5_PROPERTY4: '35 metros cuadrados.',

    ROOM6_PROPERTY1: 'Sobre Planta Baja.',
    ROOM6_PROPERTY2: '2 Camas Individuales.',
    ROOM6_PROPERTY3: 'Duermen 2 personas.',
    ROOM6_PROPERTY4: '30 metros cuadrados.',

    TITLE_AMENITIES: 'AMENIDADES:',
    ROOM1_AMENITIES1: 'WiFi.',
    ROOM1_AMENITIES2: 'TV via satélite.',
    ROOM1_AMENITIES3: 'Minibar.',
    ROOM1_AMENITIES4: 'Toallas de baño.',
    ROOM1_AMENITIES5: 'Toallas de alberca.',
    ROOM1_AMENITIES6: 'Sábanas 100% algodón.',
    ROOM1_AMENITIES7: 'Plancha y Secador (Solicitarlas).',
    ROOM1_AMENITIES8: 'Vista al lago.',
    ROOM1_AMENITIES9: 'Sillón doble.',
    ROOM1_AMENITIES10: 'Vista a la piscina.',
    ROOM1_AMENITIES11: 'Shampoo.',

    ROOM2_AMENITIES1: 'WiFi.',
    ROOM2_AMENITIES2: 'TV via satélite.',
    ROOM2_AMENITIES3: 'Vista a la piscina.',
    ROOM2_AMENITIES4: 'Vista al lago.',
    ROOM2_AMENITIES5: 'Camastros de alberca.',
    ROOM2_AMENITIES6: 'Mesas de exterior.',

    ROOM3_AMENITIES1: 'WiFi.',
    ROOM3_AMENITIES2: 'TV via satélite.',
    ROOM3_AMENITIES3: 'Refrigerador.',
    ROOM3_AMENITIES4: 'Toallas de baño.',
    ROOM3_AMENITIES5: 'Toallas de alberca.',
    ROOM3_AMENITIES6: 'Sábanas 100% algodón.',
    ROOM3_AMENITIES7: 'Plancha y Secador (Solicitarlas).',
    ROOM3_AMENITIES8: 'Vista al lago.',
    ROOM3_AMENITIES9: 'Sofá cama.',
    ROOM3_AMENITIES10: 'Vista a la piscina.',
    ROOM3_AMENITIES11: 'Shampoo.',

    ROOM4_AMENITIES1: 'WiFi.',
    ROOM4_AMENITIES2: 'TV via satélite.',
    ROOM4_AMENITIES3: 'Refrigerador.',
    ROOM4_AMENITIES4: 'Toallas de baño.',
    ROOM4_AMENITIES5: 'Toallas de alberca.',
    ROOM4_AMENITIES6: 'Sábanas 100% algodón.',
    ROOM4_AMENITIES7: 'Plancha y Secador (Solicitarlas).',
    ROOM4_AMENITIES8: 'Sillas de descanso.',
    ROOM4_AMENITIES9: 'Vista a la piscina.',
    ROOM4_AMENITIES10: 'Shampoo.',

    ROOM5_AMENITIES1: 'WiFi.',
    ROOM5_AMENITIES2: 'Toallas de baño.',
    ROOM5_AMENITIES3: 'Toallas de alberca.',
    ROOM5_AMENITIES4: 'Sábanas 100% algodón.',
    ROOM5_AMENITIES5: 'Plancha y Secador (Solicitarlas).',
    ROOM5_AMENITIES6: 'Sillas de descanso.',
    ROOM5_AMENITIES7: 'Vista a la piscina.',
    ROOM5_AMENITIES8: 'Camastros de alberca.',
    ROOM5_AMENITIES9: 'Shampoo.',

    ROOM6_AMENITIES1: 'WiFi.',
    ROOM6_AMENITIES2: 'Toallas de baño.',
    ROOM6_AMENITIES3: 'Toallas de alberca.',
    ROOM6_AMENITIES4: 'Sábanas 100% algodón.',
    ROOM6_AMENITIES5: 'Plancha y Secador (Solicitarlas).',
    ROOM6_AMENITIES6: 'Vista a la piscina.',
    ROOM6_AMENITIES7: 'Camastros de alberca.',
    ROOM6_AMENITIES8: 'Shampoo.',
    
    TITLE_FACILITIES: 'INSTALACIONES:',
    ROOM1_FACILITIES1: 'Terraza Privada.',
    ROOM1_FACILITIES2: 'Baño Privado.',
    ROOM1_FACILITIES3: 'Accesos Independientes.',
    ROOM1_FACILITIES4: 'Roof Garden (Solicitar).',

    ROOM2_FACILITIES1: 'Terraza.',
    ROOM2_FACILITIES2: 'Baño Privado.',
    ROOM2_FACILITIES3: 'Accesos Independientes.',
    ROOM2_FACILITIES4: 'Jacuzzi Privado (8 personas).',
    ROOM2_FACILITIES5: 'Área de Asador.',

    ROOM3_FACILITIES1: 'Terraza Privada.',
    ROOM3_FACILITIES2: 'Terraza Compartida.',
    ROOM3_FACILITIES3: 'Baño Privado.',
    ROOM3_FACILITIES4: 'Accesos Independientes.',
    ROOM3_FACILITIES5: 'Mini alberca-Jacuzzi.',
    ROOM3_FACILITIES6: 'Mirador (Traer telescopio Propio)',

    ROOM4_FACILITIES1: 'Terraza Privada.',
    ROOM4_FACILITIES2: 'Baño Privado.',
    ROOM4_FACILITIES3: 'Accesos Independientes.',

    ROOM5_FACILITIES1: 'Terraza Compartida.',
    ROOM5_FACILITIES2: 'Baño Privado.',
    ROOM5_FACILITIES3: 'Accesos Independientes.',

    ROOM6_FACILITIES1: 'Terraza Compartida.',
    ROOM6_FACILITIES2: 'Baño Privado.',
    ROOM6_FACILITIES3: 'Accesos Independientes.',

    TITLE_CHECKTIMES: 'Horas para Check-In y Out:',
    ROOM_CHECKIN: 'Check-In: 2:00 P.M.',
    ROOM_CHECKOUT: 'Check-Out: 12:00 P.M.',

};

export const RESERVE = {
    TITLE1 :                    'RESERVAR',
    SUBHEAD1:                   'To make a reservation request, please complete the form below and we will respond very promptly.',
    SUCCESS_TITLE: 'SU SOLICITUD A SIDO ENVIADA!',
    SUCCESS_MESSAGE: 'Debería recibir una confirmacion de la solicitud en su email. Nosotros le contactaremos para confirmar su reservación. Si usted no recibe una confirmación, por favor contactenos al (55)5406-4759', 

    FORM_SUB_PERSONAL: 'Personal Details:',

    FORM_LABEL_NAME: 'Nombre Completo:',
    FORM_LABEL_EMAIL : 'Email:',
    FORM_LABEL_PHONE : 'Teléfono:',
    FORM_LABEL_COUNTRY : 'País:',
    FORM_LABEL_CITY : 'Ciudad:',
    FORM_LABEL_AGE : 'Edad:',
    FORM_LABEL_AGE_SELECT : 'seleccione...',

    FORM_SUB_BOOKING : 'Detalles de la Reservación:',

    FORM_LABEL_CHECKIN_DATE : 'Fecha llegada:',
    FORM_LABEL_CHECKOUT_DATE : 'Fecha Salida:',
    FORM_LABEL_ROOMTYPE : 'Tipo de habitación:',
    FORM_LABEL_ROOMTYPE_SELECT: 'seleccione...',
    FORM_LABEL_ROOMTYPE_SELECT_OP1: 'Junior (Planta baja)',
    FORM_LABEL_ROOMTYPE_SELECT_OP2: 'BlueView (Planta baja)',
    FORM_LABEL_ROOMTYPE_SELECT_OP3: 'RedSunset (Primer piso)',
    FORM_LABEL_ROOMTYPE_SELECT_OP4: 'Family Suite (Dos pisos)',
    FORM_LABEL_ROOMTYPE_SELECT_OP5: 'Master Suite (Segundo piso)',
    FORM_LABEL_ROOMTYPE_SELECT_OP6: 'Master Suite + Roof Garden (2 Pisos)',
    FORM_LABEL_NUM_ROOMS : 'Núm. de habitaciones:',
    FORM_LABEL_NUM_ROOMS_SELECT: 'seleccione...',
    FORM_LABEL_NUM_ROOMS_SELECT_OP6: 'Más de 5',
    FORM_LABEL_NUM_ADULTS : 'Núm. de adultos:',
    FORM_LABEL_NUM_ADULTS_SELECT: 'seleccione...',
    FORM_LABEL_NUM_ADULTS_SELECT_OP6: 'Más de 5',
    FORM_LABEL_CHILDREN12 : 'Niños 6 a 12 años:',
    FORM_LABEL_CHILDREN12_SELECT: 'seleccione...',
    FORM_LABEL_CHILDREN12_SELECT_OP6: 'Más de 5',
    FORM_LABEL_CHILDREN6 : 'Niñis menores de 6 años:',
    FORM_LABEL_CHILDREN6_SELECT : 'seleccione...',
    FORM_LABEL_CHILDREN6_SELECT_OP6: 'Más de 5',

    FORM_SUB_MOREINFO : 'Información Adicional:',

    FORM_LABEL_BREAKFAST : 'Para desayuno quiero...',
    FORM_LABEL_BREAKFAST_SELECT : 'seleccione...',
    FORM_LABEL_BREAKFAST_SELECT_OP1 : 'Desayuno Quinta lili (Comida mexicana)',
    FORM_LABEL_BREAKFAST_SELECT_OP2 : 'Fruta, Jugo y huevos',
    FORM_LABEL_BREAKFAST_SELECT_OP3 : 'Requerimiento especial',
    FORM_LABEL_INTERESTS : 'Interesado(a) en:',
    FORM_LABEL_INTERESTS_SELECT : 'seleccione...',
    FORM_LABEL_INTERESTS_SELECT_OP1 : 'Paseo en lancha',
    FORM_LABEL_INTERESTS_SELECT_OP2 : 'Clases Ski acuático',
    FORM_LABEL_INTERESTS_SELECT_OP3 : 'Cuatrimotos',
    FORM_LABEL_INTERESTS_SELECT_OP4 : 'Cocinera',
    FORM_LABEL_INTERESTS_SELECT_OP5 : 'Kayaks',
    FORM_LABEL_INTERESTS_SELECT_OP6 : 'Organizar evento',
    FORM_LABEL_HOW_FINDUS : '¿Cómo nos encontró?',
    FORM_LABEL_HOW_FINDUS_SELECT : 'seleccione...',
    FORM_LABEL_HOW_FINDUS_SELECT_OP1 : 'Tripadvisor',
    FORM_LABEL_HOW_FINDUS_SELECT_OP2 : 'Google',
    FORM_LABEL_HOW_FINDUS_SELECT_OP3 : 'Amigos',
    FORM_LABEL_HOW_FINDUS_SELECT_OP4 : 'Otro (Por favor especifique en su mensaje)',
    FORM_LABEL_MESSAGE : 'Mensaje:',

};

export const FACILITIES = {
    TITLE1 : 'INSTALACIONES',
    CONTENT_MAIN:  'Quinta Lili Tequesquitengo cuenta con espacios acogedores, funcionales y prácticos los cuales garantizaran su descanso tales como:', 
    CONTENT_LISTITEM1: 'Alberca y Área de camastros.',
    CONTENT_LISTITEM2:'Roof Garden.',
    CONTENT_LISTITEM3:'Mini Alberca.',
    CONTENT_LISTITEM4:'Honor Bar.',
    CONTENT_LISTITEM5:'Estacionamiento.',
    CONTENT_LISTITEM6:'Acceso directo al Lago.',
    CONTENT_LISTITEM7:'Área de Asador, cocina para huéspedes, área de bar.',
    CONTENT_LISTITEM8:'Terrazas compartidas y Privadas con vista al Lago.',
    CONTENT_P1: '',
};

export const SERVICES = {
    TITLE1 : 'SERVICIOS',
    CONTENT_MAIN:  'La finalidad de Quinta lili Tequesquitengo es hacer sentir a nuestros huéspedes como en buena casa y por lo tanto se hemos dotado a nuestro pequeño oasis de los siguientes servicios para su conveniencia:',
    CONTENT_LISTITEM1: 'Desayuno Gourmet.',
    CONTENT_LISTITEM2:'Wifi.',
    CONTENT_LISTITEM3:'Honor Bar.',
    CONTENT_LISTITEM4:'Limpieza de habitaciones diariamente.',
    CONTENT_LISTITEM5:'Cocinera (solicitar) y Parrilla.',
    CONTENT_LISTITEM6:'Acceso empedrado al Lago para Lanchas.',
    CONTENT_LISTITEM7:'Kayaks.',
    CONTENT_LISTITEM8:'Lancha.',
    CONTENT_LISTITEM9:'Party Planner.',
    CONTENT_LISTITEM10:'Cuatrimotos.',
    CONTENT_P1: '',
};

export const AMENITIES = {
    TITLE1 : 'AMENIDADES',
    CONTENT_MAIN:  'Porque los pequeños detalles crean grandes memorias nos complacemos en informarle de las siguientes Amenidades que sin duda ayudaran a que su estancia sea inolvidable:',
    CONTENT_LISTITEM1: 'Sabanas, Almohadas y Toallas 100% Algodón.',
    CONTENT_LISTITEM2:'Toallas de Baño, Alberca y manos.',
    CONTENT_LISTITEM3:'Shampoo.',
    CONTENT_LISTITEM4:'TV vía Satélite.',
    CONTENT_LISTITEM5:'Roku (solicitar).',
    CONTENT_LISTITEM6:'Placas eléctricas para control de Insectos.',
    CONTENT_LISTITEM7:'Ventiladores.',
    CONTENT_LISTITEM8:'Agua Purificada (solo en Bar).',
    CONTENT_LISTITEM9:'Refrigerador en habitación.',
    CONTENT_LISTITEM10:'Camas extras.',
    CONTENT_LISTITEM11:'Jacuzzi- Mini Alberca.',
    CONTENT_LISTITEM12:'Pantalla Central con Señal vía Satélite.',
    CONTENT_P1: '',
};

export const CONTACT = {
    TITLE1 : 'CONTACTO',
    SUBTITLE1: 'Para preguntas:',
    EMAIL:  ``, 
    WHATSAPP_TITLE: 'CHAT EN VIVO',
    WHATSAPP: '+52-1-55-5406-4759',
    LINKS_TITLE: 'SITIOS RELACIONADOS:',
    LINK1: 'http://www.villalili.mx',
    LINK2: 'http://www.quintalili.mx',
};

export const BIERCITO = {
    TITLE1 : 'BIERCITO CRAFT BEER',
    CONTENT_MAIN: 'Quinta Lili Tequesquitengo tenemos el placer  de ofrecer nuestra gama de cervezas artesanales especialmente fabricadas por nosotros en forma artesanal, creativa y con materias primas de excelente calidad.',
    CONTENT_MAIN2: 'Digamos Salud, Cheers , Prost o Slainte con Biercito en Teques.',
}

export const TEQUES = {
    TITLE1: 'TEQUESQUITENGO MORELOS',
    CONTENT_MAIN: 'Tequesquitengo es un poblado del municipio de Jojutla en el estado de Morelos, México. Como está ubicado a orillas del Lago de Tequesquitengo se ha convertido en uno de los más importantes destinos turísticos de la región.',
    CONTENT_MAIN2: 'Está ubicado a 111 KMS de la CD de Mexico y a 39KMS al sur de la CD de Cuernavaca. El lago en forma de gota tiene las siguientes dimensiones: el espejo de agua mide 4.2 km de largo, 2.5 km de ancho y 16 km de perímetro, está ubicado a 887.37 metros sobre el nivel del mar entre Puente de Ixtla y Jojutla de Juárez en el Estado de Morelos.',
    CONTENT_MAIN3: 'Quinta Lili Tequesquitengo tiene una localización privilegiada dentro del area del lago, la Zona conocida como la Bota la cual esta a escasos minutos del acceso a la autopista del sol, El puerto de Tequesquitengo y de más atracciones como:',
    CONTENT_LISTITEM1: 'Jardines Mexico.',
    CONTENT_LISTITEM2:'Clubes de Paracaidismo.',
    CONTENT_LISTITEM3:'Clubes de esquí acuático.',
    CONTENT_LISTITEM4:'Parques Acuáticos.',
    CONTENT_LISTITEM5:'Rafting y espeleología (Rio Amacuzac).',
    CONTENT_LISTITEM6:'Globos Aerostáticos.',
    CONTENT_LISTITEM7:'Haciendas y Hoteles  para la organización de Eventos Sociales.',
    CONTENT_LISTITEM8:'Taxco, Guerrero a 65 KMS.',
    CONTENT_LISTITEM9:'Buceo y Pesca deportiva.',
    LINKS_TITLE:'Algunos links sobre Tequesquitengo:',
    LINK1: 'https://jardinesdemexico.com/',
    LINK2: 'https://skydivemexico.mx/',
    LINK3: 'https://elrollo.com.mx/',
    LINK4: 'http://www.acuaski.com/',
    LINK5: 'https://www.mexicorafting.com/',
}


/*********************/
//      Buttons      //
/*********************/

export const BUTTON = {
    CTA_RESERVE : 'Reservar ahora!',
    VIEW : 'Visitar',
    CONTACT: 'Contáctenos',
    FORM_SEND: 'Enviar Email',
    BTN_GO: 'Ver Más',
    BTN_BACK_UP: 'Ir arriba',
    BTN_BACK_ROOMS: 'Volver a habitaciones',
    BTN_BACK_HOME: 'Página Principal',
};

/*********************/
//      Alerts      //
/*********************/

export const ALERTS = {
    EMAIL_VALID : 'Email looks correct!',
    EMAIL_INVALID: 'It must be a valid email',
}

/* export const SIGNUP_FORM = <iframe width="540" height="305" src="https://sibforms.com/serve/MUIEAODg3-VvLC8ovprXlM6_NzvvQp5tzr1KDA6XYiYNJuePl1JmvjCygYPHfgmlcvaM41fGnXOdHyl0P_1clKmecMAd68d731zRzXIlffLzqPk9zLFhc7XcsVPKnp8uQPv9pSa5MT6yjwQdNRaelfI8bdflgFi1lFJftZWuuxkzQ33MSpGnqQhEDVihuUtL56BcQr9OqnUakKvo" frameborder="0" scrolling="auto" allowfullscreen style="display: block;margin-left: auto;margin-right: auto;max-width: 100%;"></iframe> */