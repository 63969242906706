import React from 'react';
import { node } from 'prop-types';

import './Layout.css';
import AutoShowNavBar from './NavBar/AutoShowNavBar';
import Footer from './Footer';

class Layout extends React.Component {

  render() {
    return (
      <React.Fragment>
        <AutoShowNavBar lang={this.props.lang} toggleLanguage={this.props.toggleLanguage} />
        <div className="has-navbar-fixed-top">
          {this.props.children}
        </div>
        <Footer />
      </React.Fragment>
    )
  }

}


Layout.propTypes = {
  children: node.isRequired,
};

export default Layout;
