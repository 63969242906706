export const HOME = '/';
export const MENU1 = '/about';
export const MENU2 = '/rooms';
export const MENU3 = '/reserve';
export const MENU4 = '/facilities';
export const MENU5 = '/services';
export const MENU6 = '/amenities';
export const MENU7 = '/tequesquitengo';
export const MENU8 = '/biercito_brew';
export const MENU9 = '/contact';

export const ROOMS = {
    ROOM1: '/rooms/mastersuite',
    ROOM2: '/rooms/roofgarden',
    ROOM3: '/rooms/familysuite',
    ROOM4: '/rooms/redsunset',
    ROOM5: '/rooms/blueview',
    ROOM6: '/rooms/juniorroom',
}

export const TEQUESLINKS={
    LINK1: 'https://jardinesdemexico.com/',
    LINK2: 'https://skydivemexico.mx/',
    LINK3: 'https://elrollo.com.mx/',
    LINK4: 'http://www.acuaski.com/',
    LINK5: 'https://www.mexicorafting.com/',
}



export const EMAIL = "mailto:jim_i011@yahoo.com";
export const WHATSAPP_DIRECT_MESSAGE = "https://api.whatsapp.com/send?phone=523317239979&text=Me%2C%20interesa%2C%20un%2C%20sitio%2C%20Web!";
export const SOCIAL_GOOGLE = '/contacto'
// export const SOCIAL_FACEBOOK =
// export const SOCIAL_LINKEDIN =
// export const SOCIAL_TWITTER =
// export const SOCIAL_YOUTUBE =